.page-search-node {
	section#content {
		background: none;
		margin-left: 20px;
		margin-right: 20px;
		.search-advanced {
			margin-top: $vert-space;
		}
		.search-result.card {
			padding: $vert-space $horz-space;
			background:$azure_white;
			.card-title a {
				color:$primary;
				&:hover {
					color:$skobeloff;
				}
			}
		}
	}
	.search-form {
		margin-bottom: 30px;
		.form-wrapper {
			background-color: $azure_white;
			padding: 30px 20px;
			overflow: hidden;
			.form-item-keys label {
				margin-bottom: 20px;
				font-family: $subtitle-font-family;
				display: inline-block;
			}
			input#edit-submit {
		    float: right;
		    margin-top: 15px;
			}
		}
	}
}
