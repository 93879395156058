// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: none;
$slick-default-controls-bg         				: none;
$slick-default-prev-bg             				: $skobeloff $arrow-white-left no-repeat scroll 50% 50% / 16px 16px;
$slick-default-prev-hover-bg      				: $tiger_eye $arrow-charleston-left no-repeat scroll 50% 50% / 16px 16px;
$slick-default-next-bg            				: $skobeloff $arrow-white-right no-repeat scroll 50% 50% / 16px 16px;
$slick-default-next-hover-bg      				: $tiger_eye $arrow-charleston-right no-repeat scroll 50% 50% / 16px 16px;
$slick-default-dot-border         				: 1px solid $star_command;
$slick-default-dot-hover-border    				: 1px solid $tiger_eye;
$slick-default-dot-active-border       	  : 1px solid $dark_imperial;
$slick-default-dot-active-bg-color 				: $dark_imperial;
$slick-default-dot-active-hover-bg-color 	: $tiger_eye;


$slick-alt-1-nav-bg                				: none;
$slick-alt-1-controls-bg           				: none;
$slick-alt-1-prev-bg               				: $tiger_eye $arrow-charleston-left no-repeat scroll 50% 50% / 16px 16px;
$slick-alt-1-prev-hover-bg         				: $star_command $arrow-white-left no-repeat scroll 50% 50% / 16px 16px;
$slick-alt-1-next-bg               				: $tiger_eye $arrow-charleston-right no-repeat scroll 50% 50% / 16px 16px;
$slick-alt-1-next-hover-bg         				: $star_command $arrow-white-right no-repeat scroll 50% 50% / 16px 16px;
$slick-alt-1-dot-default-border    				: 1px solid $white;
$slick-alt-1-dot-hover-border      				: 1px solid $star_command;
$slick-alt-1-dot-active-border       	    : 1px solid $white;
$slick-alt-1-dot-active-bg-color   				: $white;
$slick-alt-1-dot-active-hover-bg-color 		: $star_command;


$slick-alt-2-nav-bg                 			: $azure_white; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $star_command $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: $dark_imperial $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: $star_command $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: $dark_imperial $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: 2px solid $star_command;
$slick-alt-2-dot-hover-border      				: 2px solid $star_command;
$slick-alt-2-dot-active-bg-color   				: $star_command;
$slick-alt-2-dot-active-hover-bg-color 		: $star_command;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : rgba(38, 41, 44, 0.9);
$slide-text-bg-mobile-color: $charleston_grey;
$slide-text-color          : $white;
$slide-text-font           : 1.222rem/130% $base-font-alt-family;

// SLIDESHOW CONTROLS

$slide-default-prev-bg             				: $tiger_eye $arrow-charleston-left no-repeat scroll 50% 50% / 28px 28px;
$slide-default-prev-hover-bg      				: rgba(0, 123, 184, 0.7) $arrow-white-left no-repeat scroll 50% 50% / 28px 28px;
$slide-default-next-bg            				: $tiger_eye $arrow-charleston-right no-repeat scroll 50% 50% / 28px 28px;
$slide-default-next-hover-bg      				: rgba(0, 123, 184, 0.7) $arrow-white-right no-repeat scroll 50% 50% / 28px 28px;
$slide-default-dot-border         				: 1px solid $white;
$slide-default-dot-hover-bg-color       	: $star_command;
$slide-default-dot-hover-border    				: 1px solid $star_command;
$slide-default-dot-active-bg-color 				: $white;
$slide-default-dot-active-border  				: 1px solid $white;