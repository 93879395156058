// /* CARD GENERAL */
@mixin card-hover() {
	.card-text {
		//color:$card-text-hover-color;
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color: $card-action-hover;
		}
		.card-event-date {
			.card-event-month-year-wrapper,
			.card-event-day-wrapper {
				color:$primary;
			}
		}
		.card-location {
			color:$tertiary;
		}
	}
}

@mixin card-hover-alt() {

	.card-text {
		//color:$card-text-hover-alt-color;
			background-color:$card-hover-alt-bg-color;
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color: $card-action-hover-alt;
		}
	}
	.card-text:before {
		background: $card-border-top-imperial;
	}
	
	.card-event-date {
		.card-event-date-wrapper {
			background:$dot-bg-navy;
		}
	}
}


// /* CARD BASE COLOUR OPTIONS */

@mixin card-light() {
	border-color: $card-light-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-light-title-color;
	}
	.card-text {
		color:$card-light-text-color;
		background-color: $card-light-bg-color;

		a {
			color:$card-light-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-light-title-color;
			}
			&:hover {
				color:$card-light-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-light-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-light-bg-color;
		}
	}

	.card-subtitle {
		color: $card-light-subtitle-color;
	}
	
	.card-display-date,
	.card-date-display-override {
		color:$tertiary;
	}
	
	.card-event-date {
		.card-event-month-year-wrapper,
		.card-event-day-wrapper {
			color:$primary;
		}
		.card-event-date-wrapper {
			background:$dot-bg-white;
		}
	}
}

@mixin card-medium() {
	border-color: $card-medium-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-medium-title-color;
	}
	.card-text {
		color:$card-medium-text-color;
		background-color: $card-medium-bg-color;

		a {
			color:$card-medium-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-medium-title-color;
			}
			&:hover {
				color:$card-medium-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-medium-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-medium-bg-color;
		}
	}

	.card-subtitle {
		color: $card-medium-subtitle-color;
	}
	
	.card-display-date,
	.card-date-display-override {
		color:$tertiary;
	}
	
	.card-event-date {
		.card-event-month-year-wrapper,
		.card-event-day-wrapper {
			color:$primary;
		}
		.card-event-date-wrapper {
			background:$dot-bg-white;
		}
	}
}

@mixin card-dark() {
	border-color: $card-dark-border-color;

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-dark-title-color;
	}
	.card-text {
		color:$card-dark-text-color;
		background-color: $card-dark-bg-color;

		a {
			color:$card-dark-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-dark-title-color;
			}
			&:hover {
				color:$card-dark-action-hover;
				text-decoration: underline;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-dark-action-hover;
					text-decoration: none;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-dark-bg-color;
		}
	}

	.card-subtitle {
		color: $card-dark-subtitle-color;
	}
	.card-display-date,
	.card-date-display-override {
		color:$white;
	}
	.card-event-date {
		.card-event-month-year-wrapper,
		.card-event-day-wrapper {
			color:$white;
		}
		.card-event-date-wrapper {
			background:$dot-bg-purple;
		}
	}
}


// /* CARD MORE LINKS */

@mixin card-more-link-default() {
	a.card {
		background-color: $card-medium-bg-color;
		color: $card-more-link-default-color;
		&:after {
			background: $card-more-link-default-icon;
		}
		&:hover {
			color: $card-more-link-default-hover-color;
			&:after {
				background: $card-more-link-default-hover-icon;
			}
		}
		&:before {
			background: $card-border-bottom-azure;
		}
	}
}

@mixin card-more-link-alt() {
	a.card {
		color: $card-more-link-alt-color;
		background-color: $card-light-bg-color;
		&:after {
			background: $card-more-link-alt-icon;
		}
		&:hover {
			color: $card-more-link-alt-hover-color;
			&:after {
				background: $card-more-link-alt-hover-icon;
			}
		}
		&:before {
			background: $card-border-bottom-white;
		}
	}
}

@mixin card-more-link-alt-2() {
	a.card {
		background-color: $card-dark-bg-color;
		color: $card-more-link-alt-2-color;
		&:after {
			background: $card-more-link-alt-2-icon;
		}
		&:before {
			background: $card-border-bottom-koamaru;
		}
		&:hover {
			color: $card-more-link-alt-2-hover-color;
			background-color: $dark_imperial;
			&:after {
				background: $card-more-link-alt-2-hover-icon;
			}
			&:before {
				background: $card-border-bottom-imperial;
			}
		}
		
	}
}


// /* CARD FEED MORE LINKS */
@mixin card-feed-link-default() {
	a {
		//background:$card-feed-link-default-bg;
		color: $card-feed-link-default-color;
		&:after {
			background: $card-feed-link-default-icon;
		}
		&:hover {
			//background:$card-feed-link-default-hover-bg;
			color: $card-feed-link-default-hover-color;
			&:after {
				background: $card-feed-link-default-hover-icon;
			}
		}
	}
}


@mixin card-feed-link-alt() {
	a {
		background:$card-feed-link-alt-bg;
		color: $card-feed-link-alt-color;
		&:after {
			background: $card-feed-link-alt-icon;
		}
		&:hover {
		background:$card-feed-link-alt-hover-bg;
			color: $card-feed-link-alt-hover-color;
			&:after {
				background: $card-feed-link-alt-hover-icon;
			}
		}
	}
}