.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}

// DISPLAY YEAR FILTER AND HIDE DEFAULT FROM-TO FILTERS
// #views-exposed-form-publications-all,
// #views-exposed-form-resources-all,
// #views-exposed-form-resources-publications-all {
//   #edit-from-wrapper,
//   #edit-to-wrapper {
//     display: none !important;;
//   }
//   #edit-year-wrapper {
//     display: block !important;
//   }
// }

.views-exposed-form {
    padding: 20px;
    margin-bottom: 40px;
		background:white;
		label {
			color: $charleston_grey;
			font: 600 1.222em/130% $subtitle-font-family;
			margin:10px 0;
		}
		.views-exposed-widget .form-submit {
			margin-top:29px;
		}
}

.form-item-keywords input.form-text{
	&::-webkit-input-placeholder {
		color: $primary;
		font-size:0.777em;
		padding:3px 10px 6px;
		margin:0;
		margin-top:-5px;
	}
}

// PALETTE SPECIFIC STYLING

.palette-default,
.palette-alt-3,
.palette-highlight {
	.views-exposed-form {
	   background-color:$azure_white;
		 #{$all-text-inputs},
		 select[multiple=multiple],
		 textarea,
		 select {
			 background-color: white;
		 }
	}
}

.palette-alt-1,
.palette-alt-2,
.side-row {
	.views-exposed-form {
	   background-color:$white;
		 #{$all-text-inputs},
		 select[multiple=multiple],
		 textarea,
		 select {
			 background-color: $alice_blue;
		 }
	}
}

// RESPONSIVE STYLING

.views-exposed-form {
	.views-exposed-widget {
		width:100%;
		padding:0;
		
		&.views-submit-button,
		&.views-reset-button {
			width:100%;
			min-width:110px;
			.form-submit {
				width:100%;
				margin-bottom: 10px;
				margin-top:5px;
			}
		}
		&.views-reset-button .form-submit {
			margin-bottom: 0;
		}
		@media(min-width:540px) {
			width:50%;
			padding-right:20px;
			&:nth-child(2),
			&:nth-child(5),
			&:nth-child(7) {
				padding-right:0;
			}
			&.views-submit-button,
			&.views-reset-button {
				width:25%;
				float:left;
				.form-submit {
					margin-bottom: 0px;
					margin-top:10px;
					//margin-right:10px;
				}
			}
			&.views-submit-button {
				margin-right:10px;
			}
		}
		@media(min-width:960px) {
			width:33.3%;
			&:nth-child(5) {
				padding-right:0;
			}
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(6) {
				padding-right:20px;
			}
			&.views-submit-button,
			&.views-reset-button {
				width:15%; 
				padding-right:10px;
			}
		}
		@media(min-width:1200px) {
			width:16.5%;
			width:20%;
			&:nth-child(3),
			&:nth-child(5)  {
				padding-right:20px;
			}
			&:nth-child(6)  {
				padding-right:0;
			}
		}
		.container-inline-date .form-item {
			width:100%;
			.date-padding {
				width:100%
			}
		}
		select {
			width: 100%;
		}
	}
}

// SIDEBAR

.side-row .views-exposed-form  {
	label {
		margin:10px 0 15px;
	}
	.views-exposed-widget {
		width:100%;
		padding:0;
		&.views-submit-button,
		&.views-reset-button {
			width:48.8%;
			margin-top:10px;
		}
		&.views-submit-button {
			margin-right:10px;
		}
		select { width:100%;}
	}
}

//CONTENT MAIN / ADDITIONAL + SIDEBAR

.one-sidebar,
.two-sidebars {
	.postscript-first,
	.content-main,
	.content-additional {
		.views-exposed-form {
			.views-exposed-widget-submit {
		    width:100%;
				float:right;
				@media(min-width:960px) {
					width:50%;
				}
				@media(min-width:1200px) {
					width:34%;
				}
			}
			.views-exposed-widget {
				&.views-submit-button,
				&.views-reset-button {
					width:100%;
				}
				@media(min-width:540px) {
					&.views-submit-button,
					&.views-reset-button {
						width:48%;
					}
				}
				&.views-reset-button {
					padding-right:0;
				}
				@media(min-width:960px) {
					width:50%;
					&:nth-child(2),
					&:nth-child(4) {
						padding-right:0;
					}
					&:nth-child(3),
					&:nth-child(6) {
						padding-right:20px;
					}
				}
				@media(min-width:1200px) {
					width:33%;
					&:nth-child(3) {
						padding-right:0;
					}
					&:nth-child(2),
					&:nth-child(4) {
						padding-right:20px;
					}
					&.views-reset-button {
						padding-right:6px;
					}
				}
			}
		}
	}
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.views-exposed-form {
			.views-exposed-widget {
				@media(min-width:1200px) {
					&.views-submit-button,
					&.views-reset-button {
						width:48%;
					}
					&.views-submit-button .form-submit {
						margin-bottom:5px;
					}
				}
			}
		}
	}
}

.one-sidebar,
.two-sidebars,
.no-sidebars {
	.postscript-first {
		.views-exposed-form {
			.views-exposed-widget-submit {
		    width:100%;
				float:right;
				@media(min-width:960px) {
					width:50%;
				}
				@media(min-width:1200px) {
					width:34%;
				}
			}
			.views-exposed-widget {
				&.views-submit-button,
				&.views-reset-button {
					width:100%;
				}
				@media(min-width:540px) {
					&.views-submit-button,
					&.views-reset-button {
						width:48%;
					}
				}
				&.views-reset-button {
					padding-right:0;
				}
				@media(min-width:960px) {
					width:50%;
					&:nth-child(2),
					&:nth-child(4) {
						padding-right:0;
					}
					&:nth-child(3),
					&:nth-child(6) {
						padding-right:20px;
					}
				}
				@media(min-width:1200px) {
					width:33%;
					&:nth-child(3) {
						padding-right:0;
					}
					&:nth-child(2),
					&:nth-child(4) {
						padding-right:20px;
					}
					&.views-reset-button {
						padding-right:6px;
					}
				}
			}
		}
	}
}