#site-footer {
	.outer-wrapper {
		padding:0 $horz-space/2;
	}

	h2 {
		color:$footer-first-text;
		font: $footer-title;
	}
	
	ul:not(.contextual-links) {
		padding: 0;

		li {
			list-style: none;
			line-height: 1.5em;
		}
	}
	
	.card-follow-us-on ul li { display: inline-block; }

	.footer-first {
		background: $footer-first-bg;
		color: $footer-first-text;
		padding:$vert-space 0;
		position: relative;
		@include palette-footer-first-slick-controls;
			&:before {
				content:"";
				height:20px;
				width:100%;
				background:$footer-bg no-repeat 50% 50% / 100% 100%;
				display: inline-block;
				position: absolute;
				top: -10px;
			}
		
		.outer-wrapper {
			border-bottom:$footer-first-border;
			padding-bottom:$vert-space/2;
		}
		
		.card {
			@include palette-footer-first-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-first-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-first-card-feed-link;
		}		

		
		a {
			color:$footer-first-action;
			&:hover {
				color:$footer-first-action-hover;
			}
		}
	}
	.footer-second {
		background: $footer-second-bg;
		color: $footer-second-text;
		padding:$vert-space 0;
		@include palette-footer-second-slick-controls;
		
		a {
			color:$footer-second-action;
			&:hover {
				color:$footer-second-action-hover;
			}
		}
		.card {
			@include palette-footer-second-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-second-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-second-card-feed-link;
		}		
		
		section.block {
			margin-bottom:$vert-space;
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
			&:last-child {
				margin-bottom:0;
			}
		}
		.footer-block {
			margin-bottom:$vert-space*2;
			
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
		}
		
	}
	.footer-third {
		background: $footer-third-bg;
		color: $footer-third-text;
		padding:$vert-space/2 0;
		text-align:center;
		font-size:0.875em;
		
		a {
			color:$footer-third-action;
			&:hover {
				color:$footer-third-action-hover;
			}
		}
		#manta-ray-media {
			float: none;
			@include media(880px) {
				float: right;
			}
			a {
				color: $action-default;
				&:hover {
					color: $action-hover;
				}
			}
		}
		.block-menu {
			float: none;
			@include media(880px) {
				float: left;
			}
			ul {
				&.menu {
					margin-top:0;
				}
				li {
					display: inline-block;
					margin-right: 5px;
					padding-right: 10px;
					border-right: 1px solid $secondary-border-color;
				}
				li.last {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.card-links {
		display: inline-block;
		margin: 0 0 5px 0;
		text-indent: -9999px;
		
		a {
			background: $card-links-action-bg $ico-web-white no-repeat 50% 50% / 19px 19px;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0;
			
			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
				// background: $card-links-hover-bg $ico-web-over no-repeat 50% 50% / 19px 19px;
				background-color: $card-links-hover-bg;
			}
		}
		
		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-newsletter-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"] {
			background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $card-links-hover-bg $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px;
			}
		}
		
		/* twitter */
		.twitter a,
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"], 
		a[href^="http://twitter.com"] {
			background: $ico-twitter-white no-repeat scroll 50% 50% / 20px 20px $card-links-action-bg;
			&:hover {
				background: $card-links-hover-bg $ico-twitter-white no-repeat scroll 50% 50% / 19px 18px;
			}
		}

		/* facebook */
		.facebook a,
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-white no-repeat scroll 50% 50% / 18px 18px $card-links-action-bg;
			&:hover {
				background: $ico-facebook-white no-repeat scroll 50% 50% / 18px 18px $card-links-hover-bg;
			}
		}

		/* google+ */
		.g-plus a,
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background:$ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}
		
		/* Institutional profile */ 
    .institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution-white no-repeat 50% 50% / 19px 19px $card-links-action-bg;
      &:hover {
        background: $ico-institution-white no-repeat 50% 50% / 19px 19px $card-links-hover-bg;
      }
   	}

  	/* 		Research Gate */ 
    .research-gate a,
    a[href*="researchgate.net"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
			background: $ico-researchgate-white no-repeat 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-researchgate-white no-repeat 50% 50% / 19px 18px $card-links-hover-bg;
			}
    }

 /* Google Scholar */
    .g-scholar a,
    a[href*="scholar.google"] {
    background: $ico-gscholar-white no-repeat 50% 50% / 17px 19px $card-links-action-bg;
      &:hover {
        background: $ico-gscholar-white no-repeat 50% 50% / 17px 19px $card-links-hover-bg;
      }
    }  
		
		/* orcid */
    .orcid a,
    a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid-white no-repeat 50% 50% / 18px 18px $card-links-action-bg;
      &:hover {
      	background:$ico-orcid-white no-repeat 50% 50% / 18px 18px $card-links-hover-bg;
      }
    }
		
		/* YouTube */
    .youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube-white no-repeat 50% 50% / 19px 13px $card-links-action-bg;
	    &:hover {
	      background:$ico-youtube-white no-repeat 50% 50% / 19px 13px $card-links-hover-bg;
	    }
    }

		/* flickr */
    .flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr-white no-repeat 50% 50% / 18px 14px $card-links-action-bg;      
      &:hover {
        background:$ico-flickr-white no-repeat 50% 50% / 18px 14px $card-links-hover-bg;
      }
		}
			
		/* instagram */
    .instagram a,
    a[href^="https://www.instagram.com"],
		a[href^="http://www.instagram.com"],
		a[href^="https://instagram.com"], 
		a[href^="http://instagram.com"] {
      background:$ico-instagram-white no-repeat 50% 50% / 18px 18px $card-links-action-bg;
      &:hover {
        background:$ico-instagram-white no-repeat 50% 50% / 18px 18px $card-links-hover-bg;
      }
    }
	}


	.copyright {
		padding-left: 1em;
	}

	.utility-menu {
		ul {
			text-align: right;
			font-weight: 600;

			li {
				display: inline;
				padding-right: 1em;

				a {
					color: $footer-first-action;
				}
			}
		}
	}
}

// ACT EAST FOOTER LOGOS 

.footer-second-wrapper {
	width:90%;
	margin:0 auto;
	.row-max-3 {
		display: inline-block;
		width:100%;
		padding-bottom:20px;
		text-align: center;
		&:last-child {
			padding-bottom: 0;
		}
		@media(min-width: 720px) {
			width: 32%;
		}
	}
	.logo-wrapper {
		display: inline-block;
		height:110px;
		&.logo-usaid {
			background:$logo-usaid no-repeat scroll 50% 50% / 280px 120px  transparent;
			width:280px;
			height:110px;
		}
		&.logo-act {
			background:$logo-en no-repeat scroll 50% 50% / 180px 80px  transparent; 
			width:190px;
			height:110px;
		}
		&.logo-rti {
			background:$logo-rti no-repeat scroll 50% 50% / 140px 80px transparent; 
			width:190px;
			height:110px;
		}
	}
}