//@import url(https://fonts.googleapis.com/css?family=Oswald:400,700|Roboto:400,400i,700,700i);

@import url(../fonts/fonts.css);

$base-font-family      		: 'Lora', sans-serif;
$base-font-alt-family 	 	: 'Gotham Pro', serif;  
$title-font-family    		: 'True North', serif;
$subtitle-font-family 	 	: 'Gotham Pro bold', serif;  

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: 600 1.111em/1 $title-font-family;

/* FORMS */
$button-font 							: 600 $base-font-size/1 $title-font-family;

$footer-title             : 600 1.6667em/1 $subtitle-font-family;