section .section-row.section-with-promo {
  padding: 0;
  width: 100%;
  line-height: 0;
  .section-inner {
    padding: 0;
    @media(min-width:720px) {
      max-width: 96%;
      padding: 30px 30px 30px 60px;
    }
    .view-promo {
      .promo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media(min-width:720px) {
          flex-direction: row;
        }
        .card-image {
          max-width: 530px;
          background:$promo-bg no-repeat 50% 50%;
          padding:20px;
          display: inline-flex;
          z-index: 3;
        }
        a.card-promo-text { 
          padding: 80px 40px 40px; 
          display: inline-flex;
          z-index:1;
          flex-direction: column;
          margin-top: -70px;
          @media(min-width:720px) {
            padding: 30px 40px; 
            margin-left:-20px;
            margin-top: 0;
          }
          @media(min-width:1200px) {
            padding: 60px 100px;
          }
          .card-title-field {
            font-family: $title-font-family;
            font-size: 2.333em;
            color:$white;
            .promo-title {
              width: 100%;
              margin-bottom: 20px;
              line-height: 130%;
            }
          }
          .card-description {
            font-size: 1.222em;
            color:$white;
          }
        }
      }
    }
  }
  &.palette-default,
  &.palette-alt-2,
  &.palette-alt-3 {
    .promo {
      a.card-promo-text {
        background: $star_command;
        &:hover {
          background:$dark_imperial;
        }
      }
    }
  }

  &.palette-alt-1 {
    .promo {
      a.card-promo-text {
        background: $skobeloff;
        &:hover {
          background:$dark_imperial;
        }
      }
    }
  }

  &.palette-highlight {
    .promo {
      a.card-promo-text {
        background: $star_command;
        &:hover {
          background: $white;
          .card-title-field,
          .card-description {
            color:$primary;
          }
        }
      }
    }
  }
}