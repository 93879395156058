main {
	background-color:$main-page-bg;

	#content { padding:0 $horz-space/2; }
}
.postscript-first {
	background-color:$main-page-bg;
}

.front main {display: none}

.outer-wrapper {
	@include outer-container($site-max-width);
}

hr {
	border:none;
	border-bottom:$primary-border;
	margin: 0 0 $vert-space;
}
p + hr,
ul + hr,
ol + hr,
address + hr,
pre + hr,
blockquote + hr {
	margin-top: $vert-space;
}


.caption {
	display: block;
	text-align: left;
	font-size: 0.875em;

	p {
		line-height: 1.42857142857143;
		margin: 5px 0 1em;
		
		&:last-child {margin-bottom: 0;}
	}
}

.view-news-events {
	.view-header {
		margin-bottom: 36px;
	}
	&.view-id-news_events.view-display-id-events_att {
		.view-content .views-row {
	    margin-top: 2px;
			a.card {
				margin-top: 4px;
			}
		}
	}
}

.view-tagged-content .views-row {
  margin-bottom: 15px;
}

.card-related {
	h4 a {
		font: 1.222rem/130% $subtitle-font-family;
	}
}

.card-description-et {
  margin-bottom: 20px;
}

li.comment_forbidden {
    display: none;
}

section#messages {
    display: none;
}