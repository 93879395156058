html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
	color:$primary;
	font-family:$subtitle-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$base-font-family;
	}
	&.card-title-sm {
		font-family:$base-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:normal;
}
h3, h4, h5, h6 {
	font-weight:500;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$base-font-family;
	}
}
h1 {
	font-size:2rem;
	line-height:1.1875;
	margin-bottom:$vert-space;
	
	@include media($normal) {
		font-size:2.666rem;
		line-height: 130%;
	}
}
h2,
main .side-row .card-body h2 {
	font-size:1.888rem;
	line-height:1.32;
	margin-bottom:0.5em;
	font-weight: 600;

	@include media($normal) {
		font-size:2.333rem;
		line-height: 130%;
	}
}
h3 { 
	font-size:1.666rem;
	line-height:1.375; /*1.273;*/
	margin-bottom:0.5rem;
	
	@include media($normal) {
		font-size:1.888rem;
		line-height: 130%;
	}
}
h3.block__title {
	
	font-size:1.666em;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 1.888rem;
	}
}
h4 {
	font-size:1.444rem;
	line-height:1.375; /*1.3;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.666rem;
		line-height: 130%;
	}
}
h5 {
	font-size:1.222rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.444rem;
		line-height: 130%;
	}
}
h6 {
	font-size:1.111rem;
	line-height:1.375; /*1.333;*/
	margin-bottom:0.5em;

	@include media($normal) {
		font-size:1.222rem;
		line-height: 130%;
	}
}
p {
	margin:0 0 $vert-space;
}
main,
.postscript-first {
	.card-body,
	.card-description {
		p, address, pre, ul, ol {
			font-outer-wrappersize: $base-font-size;
			line-height: $base-line-height;
			
			&.smaller-text {
				font-size: 0.750rem;
				line-height: 1.5;
				
				p, li {
					font-size: 1em;
				}
			}
			&.larger-text {
				font-size: 1.250rem;
				line-height: 1.3;
				p, li {
					font-size: 1em;
				}
			}				
			@include media($normal) {
				font-size:1.125rem;
				line-height: 1.44444444444444;
				
				&.smaller-text {
					font-size: 0.750rem;
					line-height: 1.5;
				}
				&.larger-text {
					font-size: 1.250rem;
					line-height: 1.3;
				}				
			}
		}
		.caption {
			display: block;
			line-height: 1.5em;
			text-align: left;

			p {
				font-size: 0.75em;
				line-height: 1.5;

				@include media($normal) {
					font-size:0.875rem;
					line-height: 1.5;
				}
			}
		}
	}
}
b, strong {
	font-weight:700;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}

	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none;
	}
	
	&:not([href]) {
		color: inherit;
	}
}

.date,
.card-date {
	font-size:0.750em;
	font-style:normal;
}

em, i {
	font-style: italic;
}

sup, sub {
	font-size: smaller;
	&:before {
		content: "";
		margin-left: 0.25em;
	}
}
sup { 
	vertical-align: super;
	top: -0.1em;
}
sub { vertical-align:sub; }

// QUOTES 

blockquote {
	position: relative;
	margin: 0 auto;
	width:80%;
	min-height: 50px;
	margin-bottom: 20px;
	@include media($normal) {
		width:60%;
	}
	&:before,
	&:after {
		display: block;
		position: absolute;
		content: "";
		height: 50px;
		width: 35px;
	}
	&:before {
		left: -50px;
		top: -7px;
		background: transparent $quote-open-grey no-repeat scroll 0 0/100% 100%;
	}
	&:after {
		right: -50px;
		bottom: -7px;
		background: transparent $quote-close-grey no-repeat scroll 0 0/100% 100%;
	}
}

.section-row {
	&.palette-default,
	&.palette-alt-1,
	&.palette-alt-2  {
		blockquote:before {
			background: transparent $quote-open-grey no-repeat scroll 0 0/100% 100%;
		}
		blockquote:after {
			background: transparent $quote-close-grey no-repeat scroll 0 0/100% 100%;
		}
	}
	&.palette-alt-3 {
		blockquote:before {
			background: transparent $quote-open-purple no-repeat scroll 0 0/100% 100%;
		}
		blockquote:after {
			background: transparent $quote-close-purple no-repeat scroll 0 0/100% 100%;
		}
	}
	&.palette-highlight {
		blockquote:before {
			background: transparent $quote-open-tiger no-repeat scroll 0 0/100% 100%;
		}
		blockquote:after {
			background: transparent $quote-close-tiger no-repeat scroll 0 0/100% 100%;
		}
	}
}

.side-row blockquote {
	width:70%;
}

.palette-highlight {
	h1, h2, h3, h4, h5, h6 {
		color:$white;
	}
}
