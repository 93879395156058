table {
	border-collapse: separate;
	border-spacing: 0;
	margin: 0 0 $vert-space;
	width: auto;
	display: block;
	
	caption {
		background:$table-caption-bg-color;
		border-bottom:$table-caption-border;
		color::$table-caption-text-color;
		font: $table-caption-font;
		padding:$vert-space/3 $vert-space/2;
	}

	th {
		background:$table-header-bg-color;
		border-bottom: $table-header-border;
		font-weight: bold;
		padding: $vert-space/2 0;
		text-align: left;
		word-wrap: break-word;
		font-family:$subtitle-font-family;
		font-size: 1.444rem;
		color:$primary;
	}

	td {
		border-bottom: $primary-border;
		line-height: $base-line-height;
		padding: $table-padding;
		border-left:$table-cell-border;
		//border-right:$table-cell-border;
		word-wrap: break-word;
		&:first-child {
			border-left: none;
		}
	}

	thead {
		th {
			background-color: $table-header-bg-color;
			border-bottom: 0px;
			padding: $table-padding;
			border-left: $table-header-border;
			&:first-child {
				border-left:none;
			}
		}
	}
	
	tr,
	td,
	th {
		vertical-align: middle;
	}

	tbody {
		background-color: $table-bg-color;

		tr:hover > td, tr:hover > th {
			background-color: $table-hover-bg-color;
		}
		tr:first-child td {
			border-top: $table-header-border;
		}
		tr:last-child td {
			border-bottom: $table-border;
		}
		td {
			border-bottom: 0px;
			border-top: $table-border;
			padding: $table-padding;

			button {
				display: inline-block;
				font-size: .7em;
				line-height: $base-line-height;
				margin-bottom: .3em;
				margin-right: 0.5em;
				outline: none;
				padding: .3em 1em;
				width: 100%;

				@include media($narrow) {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
}

.palette-alt-2,
.side-row {
	table {
		th {
			background:$azure_white;
		}
	}
}
