.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
		&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}

	.header-outer-wrapper {
		position:relative;
		@include outer-container($site-max-width);
		min-height: 135px;
		@media(min-width: 880px) {
			min-height: auto;
		}
		.block-gtranslate {
			position: absolute;
			right: 0;
			top:10px;
			@media(min-width: 880px) {
				top:20px;
			}
			h2 {
				display: none;
			}
			select {
				background-color:$azure_white;
				min-width: 180px;
			}
		}
	}
	.wrapper-site-branding {
		padding-top: 20px;
	}
	.site-branding {
		margin-top: 90px;
    margin-left: 1.25rem;
    position: relative;
		@include media(880px) {
			top:0;
		}
		.site-title {
			position:absolute;
			left:0;
			top:-50px;
			z-index: 20;
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:160px;
				height:99px;
			}
		}
		.site-slogan {
			display: none;
		}
	}
	.block-search {
		display: none;
	}
	.main-nav-wrapper {
		padding-top:70px;
		@media(min-width: 880px) {
			padding-top: 20px;
		}
	}
	.main-navigation {
		position: relative;
		width: 100%;
		@include outer-container($site-max-width);
		max-width: unset;

		.block-menu-block {
			@media(min-width: 880px) {
				margin-right: $search-toggle-width-normal;
			}
			@media(min-width: 1150px) {
				margin-right: $search-toggle-width-wide;
			}
		}


	  .search-toggle-wrapper {
			position: absolute;
			top:-$search-toggle-height-mobile;
			right: $search-toggle-width-mobile ;
			display: block;
			@media(min-width: 880px) {
				top:0;
				right: 0;
			}
			.search-toggle {
				height: $search-toggle-height-mobile;
				width: $search-toggle-width-mobile;
				background: $search-icon;
				background-size: $search-icon-size-mobile;
				text-indent: -9999px;
				cursor:pointer;
				&:hover {
					background:$search-icon-hover;
				}
				&.active {
					background:$search-icon-active;
					background-size: $search-icon-size-mobile;
				}
				@media(min-width: 880px) {
					height: $search-toggle-height-normal;
					width: $search-toggle-width-normal;
					background:$search-icon;
					background-size: $search-icon-size-normal;
					border-left: 2px solid white;
					&:hover {
						background:$search-icon-hover;
					}
					&.active {
						background:$search-icon-active;
						background-size: $search-icon-size-normal;
					}
				}
				@media(min-width: 1151px) {
					height: $search-toggle-height-wide;
					width: $search-toggle-width-wide;
					background:$search-icon;
					background-size: $search-icon-size-wide;
					&:hover {
						background:$search-icon-hover;
					}
					&.active {
						background:$search-icon-active;
						background-size: $search-icon-size-wide;
					}
				}
			}
		}

		.block-search {
			position: absolute;
			display: block;
			right: 0;
			
			.form-actions {
		    float: left;
				input.form-submit {
					background: $search-icon-input;
					width:18px;
					height:18px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.25rem;
				}
			}
			@media(max-width: 879px) {
				width:100%
			}
			#search-block-form--2 {
				display: none;
				position: absolute;
				right: 0;
				top: 0;
				background: $search-toggle-bg-active;
				z-index: 1;
				padding: 10px;
				height:60px;
				max-width: none;
				h2 {display: none;}
				@media(max-width: 879px) {
					width:100%
				}
				.form-item {
					margin:0;
					@media(max-width: 879px) {
						width:100%
					}
				}
				.form-item-search-block-form input.form-text {
					width:320px;
					height: 40px;
					background: white;
					color: $primary;
					@media(max-width: 879px) {
						width:100%
					}
					&::placeholder {
						color:$primary;
					}
				}
				.form-wrapper {
					display:block;
					@include media(880px) {
						display:block;
					}
				}
				input[type="submit"] {
					background:$search-icon-input;
				}
				@media(min-width: 880px) {
					margin-top: $search-toggle-height-normal;
				}
				@media(min-width: 1151px) {
					margin-top: $search-toggle-height-wide;
				}
			}
		}

	}
	
	.block-menu {
		float:right;
		margin-top:1rem;
		margin-right:0.5rem;
		@include media(880px) {
			margin-right:1.5rem;
			margin-bottom:5rem;
		}
		li {
			display:inline-block;
			margin:0 0.75rem 0 0;
		}

		a {
			font:$header-menu-font;
			color:$header-menu-link-color;

			&:hover {
				color:$header-menu-link-hover;
			}
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}

.newsletter {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 20px;
  @media (min-width: 880px) {
    right: 200px;
    left: auto;
    top: 10px;
  }
	#mc_embed_signup form #mc_embed_signup_scroll {
    display: flex;
    flex-direction: row;
		label {
	    display: none;
	    margin-right: 15px;
	    font-family: "True North",serif;
	    font-size: 0.888rem;
	    text-transform: uppercase;
	    margin-top: 7px;
			@media (min-width: 720px){
		    display: inline-block;
			}
		}
		input.email {
			width: 146px;
	    border-radius: 0;
	    background: #F0F7FA;
	    border: none;
	    height: 34px;
		}
		.clear {
	    display: inline-block;
	    width: auto;
		}
		.button {
	    width: 85px;
	    padding: 1px 5px;
	    background-color: #0A717E;
	    border-radius: 0;
	    height: 34px;
		}
	}
}
