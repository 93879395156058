.item-list  ul.pager {
	//border-top: $primary-border;
	position: relative;
	padding-top: 30px;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	li {
		vertical-align: middle;
		.pager-item {
			padding:0.5em;
		}
	}
	a {
		color: $star_command !important;
		font-family: $title-font-family;
		font-weight: 600;
		height:48px;
		width:48px;
		text-indent: -9999px;
		
		&:hover {
			color:$tiger_eye !important;
		}
	}
	li.pager-next {
		padding:0;
		margin-left: 20px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			display: block;
			background: $skobeloff $arrow-white-right no-repeat 50% 50% / 16px 16px;

			&:hover {
				display: block;
				background: $tiger_eye $arrow-charleston-right no-repeat 50% 50% / 16px 16px;
			}
		}
	}
	li.pager-last {
		margin-left: -5px;
		padding:0;
		a {
			display: block;
			background: $skobeloff $arrow-white-last no-repeat 50% 50% / 16px 16px;

			&:hover {
				display: block;
				background: $tiger_eye $arrow-charleston-last no-repeat 50% 50% / 16px 16px;
			}
		}
	}
	li.pager-previous {
		margin-right: 20px;
		padding: 0;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			display: block;
			background: $skobeloff $arrow-white-left no-repeat 50% 50% / 16px 16px;

			&:hover {
				display: block;
				background: $tiger_eye $arrow-charleston-right  no-repeat 50% 50% / 16px 16px;
			}
		}
	}
	li.pager-first {
		//float: left;
		margin-right: -5px;
		padding: 0;
		a {
			display: block;
			background: $skobeloff $arrow-white-first  no-repeat 50% 50% / 16px 16px;
			&:hover {
				display: block;
				background: $tiger_eye $arrow-charleston-first  no-repeat 50% 50% / 16px 16px;
			}
		}
	}
	li,
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		color:$charleston_grey;
		padding:0.25em 0.5em;
		font-family: $title-font-family;
		//margin-top: 0.25em;
	}
}

.section-row.palette-highlight {
	.item-list  ul.pager {
		a {
			color: $white!important;

			&:hover {
				color:$star_command!important;
			}
		}
		li.pager-next {
			a {
				background: $tiger_eye $arrow-charleston-right no-repeat 50% 50% / 16px 16px;
				&:hover {
					background: $star_command $arrow-white-right no-repeat 50% 50% / 16px 16px;
				}
			}
		}
		li.pager-last {
			a {
				background: $tiger_eye $arrow-charleston-last no-repeat 50% 50% / 16px 16px;
				&:hover {
					background: $star_command $arrow-white-last no-repeat 50% 50% / 16px 16px;
				}
			}
		}
		li.pager-previous {
			a {
				background: $tiger_eye $arrow-charleston-left no-repeat 50% 50% / 16px 16px;
				&:hover {
					background: $star_command $arrow-white-left no-repeat 50% 50% / 16px 16px;
				}
			}
		}
		li.pager-first {
			a {
				background: $tiger_eye $arrow-charleston-first no-repeat 50% 50% / 16px 16px;
				&:hover {
					background: $star_command $arrow-white-first no-repeat 50% 50% / 16px 16px;
				}
			}
		}
		
		li.pager-current {
			color:$tiger_eye;
		}
	}
}