// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */

// ACT EAST 

$star_command      : #007BB8;
$dark_imperial     : #15406D;
$azure_white       : #E1EEEF;
$deep_koamaru      : #3F215F;
$skobeloff         : #0A717E;
$tiger_eye         : #DD9A32;
$charleston_grey   : #26292C;
$alice_blue        : #F0F7FA;
$nickel            : #677179;
$davy_grey         : #515960;

$black             : #000;
$grey              : #666;
$white             : #FFF;

// /* TEXT */ //
$primary           : $charleston_grey; /* Titles */ 
$secondary         : $davy_grey; /* Body text */ 
$tertiary          : $nickel; /* Info text */ 


// /* LINKS */ //
$action-default      : $star_command ;
$action-hover        : $skobeloff;
$action-active       : $skobeloff; 
$action-active-hover : $skobeloff; 


// /* PALETTE BGs */ //
$default_palette   : $white;
$alt_1_palette     : $azure_white;
$alt_2_palette     : $alice_blue;
$alt_3_palette     : $white;
$highlight_palette : $dark_imperial;


// FILES

$xlsx_color	 					: #207347; /* xlsx */
$docx_color	 					: #2D5994; /* docx */
$jpg_color	 					: #D19B15; /* jpg */
$pdf_color	 					: #C11E07; /* pdf */
$pptx_color	 					: #D04727; /* pptx */
$txt_color	 					: #023A6D; /* txt */
$zip_color	 					: black; /* zip */


// /* SHADOWS */ //
$box-shadow : 0 2px 4px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : $alice_blue;
$table-cell-border        : 1px solid $tiger_eye;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $alice_blue;
$table-header-border      : 1px solid $tiger_eye;
$table-hover-bg-color     : lighten($azure_white, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
//$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow; 
$form-border-color     : $secondary-border-color;
$form-border           : none;
$form-border-radius    : 0;

$button-bg             : $skobeloff;
$button-hover-bg       : $star_command;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $azure_white !default;