.front {
	.postscript-first .section-row,
	.content-hero .section-row {
		
		h2 {
			font: 2.333rem/1.25 $title-font-family;
			.card-title {
				font-size: inherit;
				text-align: center;
				margin-bottom: 36px;
				.field-content {
				position: relative;
				&:after {
					display:inline-block;
					width:166px;
					height:6px;
					content:"";
					position: absolute;
					left: calc(50% - 83px);
					bottom: -6px;
					}
				}	
			}
		}

		.view-header h2 {
			position: relative;
			text-align: center;
			&:after {
				display:inline-block;
				width:166px;
				height:6px;
				content:"";
				position: absolute;
				left: calc(50% - 83px);
				bottom: -6px;
			}
		}

		/* PALETTE DEFAULT */
		&.palette-default {
			h2 .card-title .field-content:after,
			.view-header h2:after  {
				background:$title-border-tiger;
			}
		}
			
		/* PALETTE ALT-1 */
		&.palette-alt-1 {
			h2 .card-title .field-content:after,
			.view-header h2:after {
				 background:$title-border-tiger;
			}	
		}

		/* PALETTE ALT-2 */
		&.palette-alt-2 {
			h2 .card-title .field-content:after,
			.view-header h2:after {
				 background:$title-border-tiger;
			}
		}

		/* PALETTE ALT-3 */
		&.palette-alt-3 {
		h2 .card-title .field-content:after,
		.view-header h2:after {
				 background:$title-border-tiger;
			}
		}

			/* PALETTE HIGHLIGHT */
		&.palette-highlight {
			h2 .card-title .field-content:after,
			.view-header h2:after {
				 background:$title-border-command;
			}
		}
		
	}
}