// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:0;
			background-size:contain;
			@include media($narrow) {
				left:20px;
			}
		}
		&.slick-next {
			right:0;
			background-size:contain;
			@include media($narrow) {
				right:20px
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:20px;
			height:20px;
			margin:0 6px;
			button {
				display:inline-block;
				width:20px;
				height:20px;
				border-radius:50%;
				padding: 9px;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 20px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:33.333%;
		}
		@include media($normal) {
			width:46%;
		}
	}
}
.side-row {
	.slick-controls {
		width: 100%;
	}
}


.card-slide-title {
  display: none;
}

// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-mobile-color;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					
					.card-slide-description {
						bottom:0;
						position:relative;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding:20px 20px 40px;
						margin:0;
						color:$slide-text-color;
						min-height:66px;
						width:100%;
						text-align: center;
						
						p {
							font:$slide-text-font;
							margin: 0;
							@include media($normal) {
								font-size: 1.222em;
							}
							@include media($wide) {
								font-size: 1.444em;
							}
						}

						@include media($narrow) {
							background-color:$slide-text-bg-color;
							position:absolute;
							padding:20px 10% 40px;
						}
						@include media($normal) {
							padding:20px 10% 60px;
						}
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		// bottom:28vh;
		bottom:20px;
		width:100%;
		left:0;
		.slick-nav {
			height: 50px;
			&:empty { display: none; }
			button.slick-arrow {
				padding: 40px;
				height:80px;
				width:80px;
				&.slick-prev,
				&.slick-next {
					top:calc(50% - 240px);
					@include media($narrow) {
						top:calc(50% - 160px);
					}
					@include media($normal) {
						top:calc(50% - 230px);
					}
					@include media($wide) {
						top:calc(50% - 340px);
					}
					@media (min-width: 1900px) {
						top:calc(50% - 400px);
					}
				}
			}
			.slick-dots {
				bottom:0;
				@include media($narrow) {
					bottom:-10px;
				}
				@include media($normal) {
					bottom:10px;
				}
			}	
		}
	}
}


// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;
		margin-bottom: -15px;

		@media (min-width:1600px) {
			//max-height: 690px;
			overflow: hidden;

			.view-slider .view-content .slick-list .slick-track .views-row {
				margin: 0;

				.card-image {
					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}							

							@media (min-width:1600px) {
								left:0;
								width: 100%;
							}
						}
					}
				}
			}
		}

		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}
	}
}

.side-row {
	.view-slider {
		background: $charleston_grey;
		.view-content .slick-list .slick-track .views-row .card-slide-description {
			padding:20px;
			&:after {
				background:$slider-border-narrow no-repeat 50% 50% / 1600px 10px;
			}
		}
		.view-footer {
			bottom:0;
			.slick-nav {
				button.slick-arrow {
					top:10px;
					padding: 20px;
					width:40px;
					height: 40px;
				}
				.slick-dots {
					bottom:30px
				}
			}
		}
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%; 
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;
				
			}
		}
	}
}

.card-description {
	position: relative;
	.card-slide-description {
		&:after {
			background:$slider-border-mobile no-repeat 50% 50% / 540px 10px;
			content:"";
			height: 10px;
			width:540px;
			display: inline-block;
			position: absolute;
			top: -10px;
			left: 0;
		}
		@media (min-width:540px) {
			&:after {
				background:$slider-border-narrow no-repeat 50% 50% / 1600px 10px;
				content:"";
				width:1600px;
				top:-9px;
			}
		}
		@media (min-width:720px) {
			&:after {
				background:$slider-border-wide no-repeat 50% 50% / 1600px 9px;
				content:"";
				width:1600px;
				height:10px;
				top:-9px;
			}
		}
		@media (min-width:1101px) {
			&:after {
				top:-8px;
			}
		}
		@media (min-width:1600px) {
			&:after {
				background:$slider-border-max no-repeat 50% 50% / 1900px 10px;
				content:"";
				width:1900px;
				height:11px;
				top:-10px;
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
    margin-top: $vert-space;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}

.side-row .slick-controls .slick-dots {
    display: none!important;
}


.sidebar-second {
	.content-main,
	.content-additional {
		.section-row .slick-controls {
		    width: 100%;
		}
	}
} 
