// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	outline: none;
	margin-top: 5px;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		margin-bottom: 15px;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
}

// TAGGED CONTENT
.card-title-et {	
	h3 {
		font:$card-title-font;
	}
}

.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	font:$card-date-font;
	padding:0 0 12px; //12px 20px 10px;
}


// /* MORE LINK */
.section-row:not(.view-type-slider),
.side-row {
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;
			position: relative;
			overflow: visible;
			margin-top: 5px;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}

			&:before {
				content:"";
				height: 5px;
				width:100%;
				display: inline-block;
				position: absolute;
				bottom: -5px;
				left: 0;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
			margin-bottom: 5px;
		}
	}
}

.side-row .card-more-link {
	width:100%;
	margin-left: 0;
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	a {
		&:after {
			content: "";
			display: inline-block;
			width: 16px;
			height: 16px;
			//border-radius: 50%;
			margin-left: 10px;
			vertical-align: middle;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1em/1.22222222222222 $base-font-family;
	.card-tweet-message {
		margin-bottom: 15px;
	}
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location,
.card-type {
	font:$card-info-font;
}
.card-date {
	margin-bottom: 15px;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-event-date {
	width: 100%;
	display: inline-block;
	margin-bottom: 10px;
	.card-event-date-wrapper {
		padding:12px 20px;
		display: inline-block;
		.card-event-day-wrapper,
		.card-event-month-year-wrapper {
			float:left
		}
		.card-event-day-wrapper {
			font: 600 3.1111rem/1 $title-font-family;
		}
		.card-event-month-year-wrapper {
			font: 1.111rem/120% $title-font-family;
			padding-left: 3px;
			padding-top: 5px;
		}
	}
}

.card-date-display-override {
	font-size: 0.777em;
	margin-bottom: 15px;
	font-family:$base-font-alt-family;
}
.card-resource-type,
.card-content-type,
.card-published-by {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
	font-family:$base-font-alt-family;
}
.card-published-by {
	margin-bottom: 15px;
	width:100%;
	font-family:$base-font-alt-family;
}
.card-email {
	font-weight: 600;
}

.card-job-title {
	font-size:1em;
	line-height:1.375;
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}

.view-twitter {
	.card {
		overflow:visible;
	}
}

//Display box-link card-summary on hover 
//@include card-hidden-summary;


// BORDERS 

.card-text {
	position: relative;
}

// row-max-3, row-max-4, row-max-6 = border top of card-text  
.card,
.tweet {
	overflow: visible;
	.card-text {
		overflow: visible;
		&:before {
			content:"";
			height: 5px;
			width:100%;
			display: inline-block;
			position: absolute;
			top: -4px;
			left: 0;
		}
	}
}

// row-max-1, row-max-2 = border left of card-text 
@media(min-width:960px) {
	.row-max-1,
	.row-max-2 {
		.card {
			margin-left: 4px;
			.card-text {
				&:before {
					display: none;
				}
				&:after {
					content:"";
					height: 100%;
					width:5px;
					display: inline-block;
					position: absolute;
					top: 0;
					left: -4px;
				}
			}
		}
		.card.card-with-image {
			.card-logo,
			.card-image {
					@include span-columns(6);
					margin-right: 0;
				}
			.card-text {
				@include span-columns(6);
				//width: 59.70863%;
			}
		}
	}
}

.sidebar-second .content-additional {
	.row-max-2 {
		.card .card-text {
			&:before {
				content:"";
				height: 5px;
				width:100%;
				display: inline-block;
				position: absolute;
				top: -5px;
				left: 0;
			}
			&:after {
				display: none;
			}
		}
		.card.card-with-image {
			.card-logo,
			.card-image {
					@include span-columns(12);
					//margin-right: 0;
				}
			.card-text {
				@include span-columns(12);
			}
		}
		&.palette-default {
			.card-text:before {
				background:$card-border-top-azure;
			}
		}
		&.palette-alt-1,
		&.palette-alt-2,
		&.palette-highlight {
			.card-text:before {
				background:$card-border-top-white;
			}
		}
		&.palette-alt-3 {
			.card-text:before {
				background:$card-border-top-koamaru;
			}
		}
	}
}

.section-row {
	&.palette-default {
		&.row-max-1,
		&.row-max-2 {
			.card-text:before {
				background:$card-border-top-azure;
			}
			@media(min-width: 960px){
				.card-text:after {
					background:$card-border-left-azure;
				}	
				.card-text:before {
					display: none;
				}
			}
		}
		&.row-max-3,
		&.row-max-4,
		&.row-max-6,
		.view-id-news_events {
			.card-text:before {
				background:$card-border-top-azure;
			}
		}
	}
	&.palette-alt-1,
	&.palette-alt-2,
	&.palette-highlight {
		&.row-max-1,
		&.row-max-2 {
			.card-text:before {
				background:$card-border-top-white;
			}
			@media(min-width: 960px){
				.card-text:after {
					background:$card-border-left-white;
				}	
				.card-text:before {
					display: none;
				}
			}
		}
		&.row-max-3,
		&.row-max-4,
		&.row-max-6,
		.view-id-news_events {
			.card-text:before {
				background:$card-border-top-white;
			}
		}
	}
	&.palette-alt-3 {
		&.row-max-1,
		&.row-max-2 {
			.card-text:before {
				background:$card-border-top-koamaru;
			}
			@media(min-width: 960px){
				.card-text:after {
					background:$card-border-left-koamaru;
				}	
				.card-text:before {
					display: none;
				}
			}
		}
		&.row-max-3,
		&.row-max-4,
		&.row-max-6,
		.view-id-news_events {
			.card-text:before {
				background:$card-border-top-koamaru;
			}
		}
	}
}

.side-row {
	.card-text:before {
		background:$card-border-top-white;
	}
}

.palette-default,
.palette-alt-3 {
	.node.node-organisation {
		.card .card-logo {
			border:solid 1px $alice_blue;
			border-bottom: none;
		}
	}
	&.row-max-1,
	&.row-max-2 {
		.node.node-organisation {
			.card .card-logo {
				border-bottom: solid 1px $alice_blue;
			}
		}
	}
}

.row-max-1,
.row-max-2 {
	.view-team {
		.card.card-with-image,
		.card {
			.card-text {
				float: none;
				width: auto;
				margin:0 auto;
				max-width: 480px;
			}
		}
	}
}