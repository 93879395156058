// /* MAIN MENU */ //

$nav-base-font        	 	: 1rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: transparent;
$nav-base-bg-color        		: $alice_blue; 
$nav-base-text-color     		 	: $dark_imperial;
$nav-base-padding        			: 12px 20px;
$nav-base-hover-padding   		: 12px 20px;
$nav-sub-base-padding					: 12px 20px;
$nav-sub-base-hover-padding		: 12px 20px;
$nav-active-border            : solid 4px $dark_imperial!important;

$nav-base-padding-smaller 				: 12px 20px;
$nav-base-hover-padding-smaller 	: 12px 20px;

$nav-active-bg-color      		: $dark_imperial;
$nav-active-text-color    		: $white;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: $tiger_eye;
$nav-hover-text-color     		: $charleston_grey;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: $dark_imperial;
$nav-highlight-text-color 		: $white; 
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: $white;
$nav-sub-hover-bg-color   		: $tiger_eye;
$nav-sub-text-color       		: $secondary;
$nav-sub-hover-text-color   	: $primary;
$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

$nav-mob-text-color      						: $primary;
$nav-mob-active-text-color      		: white;
$nav-mob-active-bg-color      			: $dark_imperial;

$nav-mob-1st-bg-color     					: $alice_blue;
$nav-mob-1st-highlight-bg-color     : $dark_imperial;
$nav-mob-1st-highlight-text-color   : $white;
$nav-mob-1st-active-bg-color        : $tiger_eye;
$nav-mob-1st-active-text-color      : $primary;
$nav-mob-1st-line-color     				: none;
$nav-mob-1st-border-color     			: none;

$nav-mob-2nd-bg-color     					: $white;
$nav-mob-2nd-highlight-bg-color     : $azure_white;
$nav-mob-2nd-highlight-text-color   : $secondary;
$nav-mob-2nd-line-color     				: $azure_white; 
$nav-mob-2nd-border-color     			: none;

$nav-mob-3rd-bg-color     					: $alice_blue;
$nav-mob-3rd-highlight-bg-color     : $alice_blue;
$nav-mob-3rd-highlight-text-color   : $secondary;
$nav-mob-3rd-line-color     				: $azure_white;
$nav-mob-3rd-border-color     			: none;

$nav-mob-4th-bg-color     					: white;
$nav-mob-4th-highlight-bg-color     : purple;
$nav-mob-4th-highlight-text-color   : orange;
$nav-mob-4th-line-color     				: $azure_white;
$nav-mob-4th-border-color     			: none;

$nav-mob-border											: 1px solid $azure_white;
$nav-mob-expanded-border						: 1px solid green;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;



/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $alice_blue;
$nav-button-hover-bg-color			: $alice_blue;
$nav-button-height      				: 78px;
$nav-button-width      					: 80px;
$nav-button-bar-bg-color      	: $charleston_grey;
$nav-button-bar-hover-bg-color  : $charleston_grey;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 52px;
$nav-button-bar-border-radius 	: 8px;
