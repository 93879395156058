.card-tagline-text {
	position: relative;
	z-index: 1;
  margin: 0 30px;
	width: 90%;  
	margin:0 auto;
	@include media($narrow) {
		width: 80%
	}
	@include media($normal) {
		width: 70%
	}
  h1, h2, h3, h4, h5, h6 {
    font-family: $base-font-family!important;
  }
	p {
    color:$primary;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section-with-tagline {
	padding: 60px 0 ;
	&.palette-default,
	&.palette-alt-1,
  &.palette-alt-2,
  &.palette-alt-3 {
		background-image: $dot-bg-white;
	}
	&.palette-highlight {
		background-image: $dot-bg-navy;
		.card-tagline-text {
      color: white;
	    p {
	      color:white;
	    }
		}
	}
}