// /* HEADER */ //

$header-menu-font 				: /*0.875rem*/ 0.778rem/1 $base-font-family;
$header-search-field-font : 0.875rem/1.286 $base-font-alt-family;

$header-bg-color                   : none;

$header-menu-link-color            : $primary;
$header-menu-link-hover            : $black;

$header-search-field-bg-color      : $azure_white;
$header-search-field-text-color    : $primary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;


$search-toggle-width-mobile        : 80px;
$search-toggle-height-mobile       : 78px;
$search-toggle-width-normal        : 64px;
$search-toggle-height-normal       : 48px;
$search-toggle-width-wide          : 64px;
$search-toggle-height-wide         : 57px;

$search-icon-size-mobile           : 31px 31px;
$search-icon-size-normal           : 24px 24px;
$search-icon-size-wide             : 31px 31px;

$search-toggle-bg                  : $alice_blue;
$search-toggle-bg-hover            : $tiger_eye;
$search-toggle-bg-active           : $dark_imperial;

$search-icon: $search-imperial no-repeat 50% 50% $search-toggle-bg;
$search-icon-hover: $search-imperial no-repeat 50% 50% $search-toggle-bg-hover;
$search-icon-active: $search-white no-repeat 50% 50% $search-toggle-bg-active;
$search-icon-input: $search-charleston no-repeat 50% 50%/ 18px 18px transparent;