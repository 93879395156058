.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	// .views-row-last {
	// 	.attachment-link {
	// 		margin:0;
	// 	}
	// }

	.attachment-link {
		display:block;
		margin-bottom:$vert-space;
		margin-right: $horz-space;
		position: relative;
		overflow: visible;
		&:after {
			height:3px;
			width:100%;
			content:"";
			display: inline-block;
			position: absolute;
			top: -3px;
			left: 0;
		}
		
		.file-icon {
			float:left;
			padding: 4px 0 10px;
			//margin:0 $horz-space 0 0;
			min-width:4rem;
			.file-extension {
				padding:3px 5px 2px;
				text-transform:uppercase;
				font-size:1.111em;
        line-height:120%;
				text-align:center;
				font-weight:bold;
				font-family: $title-font-family;
			}
			.file-size {
        padding: 0px 5px 3px;
				font-size:0.777em;			
				text-align:center;
				color:$secondary;
				font-family: $base-font-alt-family;
			}
		}
		
		.file-name {
			overflow:hidden;
			padding:17px 20px;
			font-family: $subtitle-font-family;
			font-size: 1.222em;
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
		border-top: 2px solid $docx_color;
    .file-extension {
      color:$docx_color;
    }
  }
  .file-type-xlsx, .file-type-xls {
		border-top: 2px solid $xlsx_color;
    .file-extension {
      color:$xlsx_color;
    }
  }
  .file-type-pptx, .file-type-ppt {
		border-top: 2px solid $pptx_color;
    .file-extension {
      color:$pptx_color;
    }
  }
  .file-type-pdf {
		border-top: 2px solid $pdf_color;
    .file-extension {
      color:$pdf_color;
    }
  }
  .file-type-rtf, .file-type-txt {
		border-top: 2px solid $txt_color;
    .file-extension {
      color:$txt_color;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
		border-top: 2px solid $jpg_color;
    .file-extension {
      color:$jpg_color;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
		border-top: 2px solid $zip_color;
    .file-extension { 
      color:$zip_color;
    }
  }
} 


// PALETTE SPECIFIC STYLES 

.palette-default {
  .view-attachments {
    .attachment-link {
			&:after {
				background: $attachment-border-top-azure no-repeat 50% 50%;
			}
			.file-name {
	      background-color: $azure_white;
				color: $charleston_grey;
	    }
			.file-icon {
				background:$azure_white $dot-bg-white repeat 50% 50% / 80px 80px;
			}
			&:hover {
				.file-name {
					color: $skobeloff;
				}
			}
		}
  }
}

.palette-alt-1,
.palette-alt-2,
.palette-highlight,
.side-row {
  .view-attachments {
		.attachment-link {
			&:after {
				background: $attachment-border-top-white no-repeat 50% 50%;
			}
			.file-name {
				background-color: $white;
				color: $dark_imperial;
			}
			.file-icon {
				background:$white $dot-bg-white repeat 50% 50% / 80px 80px;
			}
			&:hover {
				.file-name {
					color: $skobeloff;
				}
			}
		}
  }
}

.palette-alt-3 {
  .view-attachments {
		.attachment-link {
			&:after {
				background: $attachment-border-top-koamaru no-repeat 50% 50%;
			}
	    .file-name {
				background-color: $deep_koamaru;
				color: white;
			}
			.file-icon {
				background:$deep_koamaru $dot-bg-purple repeat 50% 50% / 80px 80px;
				border-top: solid 2px $deep_koamaru;
				.file-extension,
				.file-size {
					color: $white;
				}
			}
			&:hover {
				&:after {
					background: $attachment-border-top-imperial no-repeat 50% 50%;
				}
				.file-name {
					background-color: $dark_imperial;
				}
				.file-icon {
					background:$dark_imperial $dot-bg-navy repeat 50% 50% / 80px 80px;
				}
			}
		}
  }
}

.side-row {
	.attachment-link {
		margin-right: 0;
	}
}

// RESPONSIVE STYLING

.view-attachments {
		.views-row {
			width:100%;
			float:left;
			
			@media (min-width:720px) {
				width:50%;
			}
			&:nth-child(2n+1){
				@media (min-width:720px) and (max-width:959px) {
					clear:left;
				}
			}
			
			@media (min-width:960px) {
				width:33.3%
			}
			&:nth-child(3n+1){
				@media (min-width:960px) and (max-width:1199px) {
					clear:left;
				}
			}
			
			@media (min-width:1200px) {
				width:25%			
			}
			&:nth-child(4n+1){
			 	@media (min-width:1200px) {
					clear:left;
				}
			}
		}
	}
	
.side-row {
	.view-attachments {
		.views-row {
			width:100%;
			.attachment-link {
				.file-icon {
					@media(min-width: 720px) and (max-width:960px) {
						right:calc(0% - 35%)
					}
				}
				
				.file-name {
					@media(min-width: 720px) and (max-width:960px) {
						text-align:center;
						overflow: visible;
						margin-top:60px;
						padding:20px 0 0;
					}
				}
			}
		}
	}
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments {
			.views-row {
				width:100%;
				
				@media (min-width:960px) {
					width:50%
				}
				&:nth-child(2n+1){
				 	@media (min-width:960px) {
						clear:left;
					}
				}
				&:nth-child(3n+1){
					@media (min-width:960px) {
						clear:none;
					}
				}
			}
		}
	}
}
