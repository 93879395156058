// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $subtitle-font-family;
$card-title-font        	: bold 1.222rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.222rem/1 $subtitle-font-family;
$card-feed-link-font			: bold 1.111rem/1.46153846153846 $title-font-family;
$card-info-font           : 0.777em/120% 'Gotham Pro';

$card-shadow                   : none;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : transparent;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
//$card-date-text-color          : $secondary;
$card-date-font                : $card-info-font;


/* HOVER STYLES  */

$card-action-hover                 : $skobeloff;
$card-text-hover-color             : $skobeloff;
$card-date-hover-text-color        : $skobeloff;

$card-hover-alt-bg-color           : $dark_imperial;
$card-action-hover-alt             : $white; 
$card-text-hover-alt-color         : $white;
$card-date-hover-alt-text-color    : $white;

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $primary;
$card-text-with-summary-hover-bg    : $primary;
$card-text-with-summary-title-color : white;
$card-text-with-summary-text-color  : white;


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $dark_imperial;
$card-light-action-default     : $action-default;
$card-light-action-hover       : $action-hover;
$card-light-text-color         : $tertiary;
$card-light-subtitle-color     : $tertiary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $azure_white;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $primary;
$card-medium-action-default    : $action-default;
$card-medium-action-hover      : $action-hover;
$card-medium-text-color        : $tertiary;
$card-medium-subtitle-color    : $tertiary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $deep_koamaru;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $white;
$card-dark-action-default      : $tiger_eye;
$card-dark-action-hover        : $white;
$card-dark-text-color          : $white;
$card-dark-subtitle-color      : $white;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $azure_white;
$card-more-link-default-color       : $charleston_grey;
$card-more-link-default-icon        : $arrow-charleston-right no-repeat 100% 100% / 16px 16px;
//$card-more-link-default-hover-bg    : $skobeloff;
$card-more-link-default-hover-color : $skobeloff;
$card-more-link-default-hover-icon  : $arrow-skobel-right no-repeat 100% 100% / 16px 16px;

$card-more-link-alt-bg              : $white;
$card-more-link-alt-color           : $dark_imperial;
$card-more-link-alt-icon            : $arrow-imperial-right no-repeat 100% 100% / 16px 16px;
//$card-more-link-alt-hover-bg        : $action-hover;
$card-more-link-alt-hover-color     : $skobeloff;
$card-more-link-alt-hover-icon      : $arrow-skobel-right no-repeat 100% 100% / 16px 16px;

$card-more-link-alt-2-bg            : $deep_koamaru;
$card-more-link-alt-2-color         : white;
$card-more-link-alt-2-icon          : $arrow-white-right no-repeat 100% 100% / 16px 16px;
$card-more-link-alt-2-hover-bg      : $dark_imperial;
$card-more-link-alt-2-hover-color   : white;
$card-more-link-alt-2-hover-icon    : $arrow-white-right no-repeat 100% 100% / 16px 16px;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : none; // background for the entire link
$card-feed-link-default-color       : $action-default;
$card-feed-link-default-icon        : $arrow-command-right no-repeat scroll 50% 50% / 16px 16px; //background for the default :after component
$card-feed-link-default-hover-bg    : none;
$card-feed-link-default-hover-color : $skobeloff;
$card-feed-link-default-hover-icon  : $arrow-skobel-right no-repeat scroll 50% 50% / 16px 16px;

$card-feed-link-alt-bg              : none; // background for the entire link
$card-feed-link-alt-color           : $white;
$card-feed-link-alt-icon            : $arrow-white-right no-repeat scroll 50% 50% / 16px 16px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $tiger_eye;
$card-feed-link-alt-hover-icon      : $arrow-tiger-right no-repeat scroll 50% 50% / 16px 16px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $skobeloff;
$button-link-default-border-color       : $skobeloff;
$button-link-default-color              : white;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $star_command;
$button-link-default-hover-border-color : $star_command;
$button-link-default-hover-color        : none;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $white;
$button-link-alt-border-color           : $white;
$button-link-alt-color                  : $skobeloff;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $tiger_eye;
$button-link-alt-hover-border-color     : $tiger_eye;
$button-link-alt-hover-color            : $primary;
$button-link-alt-hover-icon             : none;