fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border;
	border-radius: $form-border-radius;
	//box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-alt-family;
	font-size: 0.777em;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;
	height:34px;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background: $arrow-filter no-repeat 100% 50% / 34px 34px;
	width: auto;
	border: $form-border;
	border-radius: $form-border-radius;
	//box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-alt-family;
	font-size: 0.777em;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	height:34px;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.views-exposed-widget.views-reset-button .form-submit {
  background: $dark_imperial;
  &:hover {
    background: $star_command;
  }
}

#admin-menu .admin-menu-search input {
	height:1rem;	
}

#user-login {
	input[type="text"],
	input[type="password"] {
		background: $azure_white;
		max-width: 600px;
	}
}
	